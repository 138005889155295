<template>
  <b-card>
    <!-- media -->
    <b-row>
      <b-col cols="12" md="10">
        <b-media no-body class="d-flex flex-wrap">
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                :key="pictureurl"
                rounded
                :src="'https://api.optitmr.com/Company_Pictures/' + pictureurl"
                height="80"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75 d-flex">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t("upload") }}
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageRenderer"
            />
            <!--/ upload button -->

            <!--/ reset -->
          </b-media-body>
        </b-media></b-col
      >
      <b-col
        class="
          d-none d-sm-flex
          flex-column
          justify-content-between
          align-items-end
        "
        cols="12"
        md="2"
      >
        <!--/ media -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class=""
          @click="updateCompany"
        >
          {{ $t("save") }}
        </b-button>
        <b-button
          v-if="user.usertype == 1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class=""
          size="sm"
          @click="removeCompany"
        >
          {{ $t("removeCompany") }}
        </b-button>
      </b-col>
    </b-row>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6" md="4">
          <b-form-group :label="$t('companyName')" label-for="companyName">
            <b-form-input
              v-model="companySettings.companyname"
              :placeholder="$t('companyName')"
              name="companyName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group
            :label="$t('companyAddress')"
            label-for="companyAddress"
          >
            <b-form-input
              v-model="companySettings.address"
              :placeholder="$t('companyAddress')"
              name="companyAddress"
            />
          </b-form-group>
        </b-col>
        <!-- Company Approve Code -->

        <b-col sm="6" md="4">
          <b-form-group :label="$t('companyCity')" label-for="companyCity">
            <b-form-input
              v-model="companySettings.city"
              :placeholder="$t('companyCity')"
              name="companyCity"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group
            :label="$t('companyCountry')"
            label-for="companyCountry"
          >
            <b-form-input
              v-model="companySettings.country"
              :placeholder="$t('companyCountry')"
              name="companyCountry"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6" md="4">
          <b-form-group :label="$t('companyPhone')" label-for="companyPhone">
            <b-form-input
              v-model="companySettings.phone"
              :placeholder="$t('companyPhone')"
              name="companyPhone"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group :label="$t('companyMobile')" label-for="companyMobile">
            <b-form-input
              v-model="companySettings.mobile"
              :placeholder="$t('companyMobile')"
              name="companyMobile"
            />
          </b-form-group>
        </b-col>

        <!-- vd
    vn -->
        <b-col sm="6" md="4">
          <b-form-group :label="$t('companyVd')" label-for="companyVd">
            <b-form-input
              v-model="companySettings.vd"
              :placeholder="$t('companyVd')"
              name="companyVd"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group :label="$t('companyVn')" label-for="companyVn">
            <b-form-input
              v-model="companySettings.vn"
              :placeholder="$t('companyVn')"
              name="companyVn"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group :label="$t('currencySymbol')" label-for="currency">
            <b-form-input
              v-model="companySettings.symbol"
              placeholder="currency"
              name="currency"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6" md="4">
          <b-form-group :label="$t('ingredientPrecision')" label-for="symbol">
            <v-select
              v-model="companySettings.ingredientdecimal"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="valueOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group
            :label="$t('nutrientPrecision')"
            label-for="nutrientdecimal"
          >
            <v-select
              v-model="companySettings.nutrientdecimal"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="valueOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6" md="4">
          <b-form-group :label="$t('pricePrecision')" label-for="pricedecimal">
            <v-select
              v-model="companySettings.pricedecimal"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="valueOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6" md="4">
          <b-form-group class="mt-1">
            <b-form-checkbox
              id="productRequired"
              v-model="companySettings.forceproduct"
            >
              {{ $t("forceProduct") }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group class="mt-1">
            <b-form-checkbox
              id="symbol"
              v-model="companySettings.symbolinfront"
            >
              {{ $t("symbolfront") }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          class="d-flex d-sm-none justify-content-end align-items-start"
          cols="12"
        >
          <!--/ media -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class=""
            @click="updateCompany"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormCheckbox,
  BCard,
  BFormRadio,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormCheckbox,
    vSelect,
    // BFormRadio,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      user: getUserData(),
      companySettings: {
        symbol: "",
        pricedecimal: "",
        nutrientdecimal: "",
        ingredientdecimal: "",
        forceproduct: "",
        symbolinfront: "",
        companyLogoShowsInForm: false,
        companyname: "",
        showcompanylogoinreports: false,
        showcompanynameinreports: false,
        autosolve: true,
        id: 0,
      },
      feedListType: 1,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      counterForOneCuvalWeight: 50,
      pictureurl: "empty.jpg",
      valueOptions: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
      ],
    };
  },
  mounted() {
    var user = getUserData();
    this.companySettings = {
      symbol: user.currency,
      symbolinfront: user.symbolinfront,
      forceproduct: user.forceproduct,
      ingredientdecimal: user.ingredientdecimal,
      nutrientdecimal: user.nutrientdecimal,
      pricedecimal: user.pricedecimal,
      companyname: user.companyname,
      address: user.companyaddress,
      city: user.companycity,
      country: user.companycountry,
      phone: user.companyphone,
      mobile: user.companymobile,
      vn: user.companyvn,
      vd: user.companyvd,
      autosolve: user.autosolve ?? true,
      id: user.companyid,
      showcompanynameinreports: user.showcompanynameinreports,
      showcompanylogoinreports: user.showcompanylogoinreports,
    };
    this.pictureurl = user.companypictureurl;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.companySettings.imgstr = base64;
        this.$http
          .put(
            `/Companies/UpdateCompanyLogo/${user.companyid}`,
            JSON.stringify(base64),
            config
          )
          .then((result) => {
            if (result.data.status == "OK") {
              this.pictureurl = result.data.statusdata + "?" + Math.random();
              user.companypictureurl =
                result.data.statusdata + "?" + Math.random();
              this.$store.commit(
                "app/UPDATE_COMPANY_PICTURE_URL",
                result.data.statusdata + "?" + Math.random()
              );
              localStorage.setItem("userData", JSON.stringify(user));
            }
          });
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async removeCompany() {
      await this.$swal({
        title: this.$t("yourCompanyDeletePermenantly"),
        text: this.$t("areYouSure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          var result = await this.$http.delete(
            `/Companies/Delete/${this.user.companyid}`
          );
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          if (result.statusdata != "ERROR") {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
              useJwt.jwtConfig.storageRefreshTokenKeyName
            );

            // Remove userData from localStorage
            localStorage.removeItem("userData");
            // Reset ability
            this.$ability.update(initialAbility);

            // Redirect to login page
            this.$router.push({ name: "auth-login" });
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("deleted", { type: this.$t("company") }),
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("notDeleted", { type: this.$t("company") }),
              },
            });
          }
        } else {
          return;
        }
      });
    },
    async updateCompany() {
      var user = getUserData();

      const promise1 = this.$http.put(
        `/Companies/UpdateCompany/${user.companyid}`,
        {
          companyname: this.companySettings.companyname,
          address: this.companySettings.address,
          city: this.companySettings.city,
          country: this.companySettings.country,
          phone: this.companySettings.phone,
          mobile: this.companySettings.mobile,
          vn: this.companySettings.vn,
          vd: this.companySettings.vd,
        }
      );
      const promise2 = this.$http.put(
        `/Companies/UpdateForceandSymbol/${user.companyid}`,
        this.companySettings
      );

      const [result, result1] = await Promise.all([promise1, promise2]);

      var isOK = result.status == 200;
      if (isOK)
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("company") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("company") }),
          },
        });
      user.currency = this.companySettings.symbol;
      user.pricedecimal = this.companySettings.pricedecimal;
      user.nutrientdecimal = this.companySettings.nutrientdecimal;
      user.ingredientdecimal = this.companySettings.ingredientdecimal;
      user.forceproduct = this.companySettings.forceproduct;
      user.symbolinfront = this.companySettings.symbolinfront;
      user.companyname = this.companySettings.companyname;
      user.companyaddress = this.companySettings.address;
      user.companycity = this.companySettings.city;
      user.companycountry = this.companySettings.country;
      user.companyphone = this.companySettings.phone;
      user.companymobile = this.companySettings.mobile;
      user.vn = this.companySettings.vn;
      user.vd = this.companySettings.vd;
      user.autosolve = this.companySettings.autosolve;
      user.companyid = this.companySettings.id;
      user.showcompanynameinreports =
        this.companySettings.showcompanynameinreports;
      user.showcompanylogoinreports =
        this.companySettings.showcompanylogoinreports;
      localStorage.setItem("userData", JSON.stringify(user));
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
